<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Hintergrundwissen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-card-text>
        <p>
          Ein Operationsverständnis zur Multiplikation zu entwickeln gilt –
          insbesondere auch im Zusammenhang mit langfristigen, über das
          Einmaleins hinaus gehenden Lernprozessen – als zentrale Aufgabe des
          Mathematikunterrichts in der Grundschule. Grundvorstellungen zur
          Multiplikation umfassen dabei mehr als nur die Vorstellung des
          Vervielfachens, „auch Zusammenfassen, Vergrößern, Vermehren,
          verkürztes Addieren, Vergleichen oder Wiederholen“ (Selter, Zannetin,
          2018, S. 45) sind Denkweisen, die das Multiplizieren ansprechen (siehe
          auch <a href="/operationsverstaendnis">Operationsverständnis</a>). Durch eine geeignete Sprache, die das
          Bilden oder Vorliegen gleich großer Gruppen verdeutlicht, kann
          beispielsweise die verkürzte Addition gleichgroßer Anzahlen mit der
          Multiplikation verknüpft werden. Das kann durch die Verwendung von
          Formulierungen wie “drei Sechser”, anstatt lediglich ‚6 + 6 + 6‘ zu
          sagen, angeregt werden (Prediger, 2020).
        </p>
        <p>
          Auch wenn die Vorstellung der Multiplikation als wiederholte Addition
          als ein erster Zugang sicherlich von großer Bedeutung ist, sollte im
          weiteren Verlauf immer mehr das Ausnutzen von Ableitungsstrategien,
          ausgehend von Kernaufgaben, im Vordergrund stehen. Somit wird das
          kleine Einmaleins nicht lediglich durch das Aufsagen der
          Einmaleinsreihen automatisiert (Selter & Zannetin, 2018), sondern
          durch das Vernetzen von Aufgaben und von Darstellungen.
        </p>
        <p>
          Eine sichere und letztendlich auch automatisierte Beherrschung der
          Aufgaben des kleinen Einmaleins ist eine wichtige Voraussetzung für
          die mündliche, die halbschriftliche und die schriftliche
          Multiplikation in größeren Zahlenräumen, in anderen Zahlbereichen in
          der Sekundarstufe und auch in anderen Inhaltsbereichen wie Raum und
          Form (Berechnung von Flächeninhalten oder Volumina).
        </p>
        <br />
        <AppLiteraturEME />
      </v-card-text>
    </v-card>
    <AppBottomNavEME
      next="/einmaleins/hintergrundwissen/kernaufgaben"
      back="/einmaleins"
    />
  </div>
</template>

<script>
import AppBottomNavEME from "@/common/AppBottomNavEME";
import AppLiteraturEME from "@/common/AppLiteraturEME";

export default {
  components: {
    AppBottomNavEME,
    AppLiteraturEME,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
